<template>
  <div>
    <!--    内容开始-->
    <el-card class="box-card">
      <el-button type="primary" @click="opentan()">添加信息</el-button>
      <el-select v-model="area_id" style="margin-left: 15px" placeholder="请选择街道" @change="initialdfun">
        <el-option v-for="item in optionsone" :key="item.value" :label="item.name" :value="item.id" />
      </el-select>
      <!--    列表开始-->
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="base.auto_number" label="车牌号" />

        <el-table-column prop="info.wasted_type.name" label="作业类型" />
        <el-table-column prop="info.is_working_name" label="工作状态" />
        <el-table-column prop="info.vehicle_no" label="车辆编号" />
        <!-- <el-table-column prop="name" label="街道ID"> </el-table-column> -->
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="primary" icon="el-icon-edit" @click="editfun(scope.row)" />

            <el-button size="mini" type="danger" icon="el-icon-delete" @click="delfun(scope.row.base.id)" />
          </template>
        </el-table-column>
      </el-table>
      <!--      分页-->
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>
    <!--  弹窗内容-->
    <el-dialog title="绑定车辆" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-form ref="dform" :model="dform" label-width="80px" :rules="drules">
        <el-form-item label="车辆">
          <!-- <el-select v-model="dform.office_vehicle_id" placeholder="请选择车辆">
            <el-option v-for="item in options" :key="item.value" :label="item.auto_number" :value="item.id" />
          </el-select> -->
          <el-select
            filterable
            remote
            reserve-keyword
            :remote-method="remoteMethod"
            clearable
            :loading="loading"
            placeholder="请选择车辆"
            v-model="dform.office_vehicle_id"
            class="input-with-select"
          >
            <el-option v-for="item in options" :key="item.value" :label="item.auto_number" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="作业类型">
          <el-select v-model="dform.wasted_type" placeholder="请选择作业类型">
            <el-option v-for="item in categories" :key="item.value" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>

        <el-form-item label="街道ID">
          <el-select v-model="dform.area_id" placeholder="请选择街道">
            <el-option v-for="item in optionsone" :key="item.value" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>

        <el-form-item label="车辆编号">
          <el-input style="width: 75%" v-model="dform.vehicle_no" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelfun()">取 消</el-button>
        <el-button v-if="tantype !== 'look'" type="primary" @keyup.enter.native="addgoto()" @click="addgoto(tantype)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Fenye from './components/Fenye.vue'
import { AddVehicle, GetCarInfo, GetCarChooseInfo, GetCarStreetInfo, GetCarBoundInfo, DelVehicle } from '@/api/carManage'
import { GetautoList } from '../../../api/car'

export default {
  components: {
    Fenye
  },
  data() {
    return {
      dialogVisible: false, // 弹窗控制
      dform: {
        vehicle_no: '',
        wasted_type: '',
        area_id: '',
        office_vehicle_id: ''
      },
      area_id: '', // 街道id
      // 弹窗按钮的状态
      tantype: 'add',
      // 验证规则
      drules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }]
      },
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      },
      pageDatas: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 99999,
        // 共有多少条
        count: 0
      },
      // 列表数据
      tableData: [],
      options: [],
      categories: [],
      optionsone: [],
      loading: false
    }
  },
  mounted() {
    this.getcarstreetinfofun()
  },
  methods: {
    // 远程搜索下拉合同
    async remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        await this.getcarinfofun(query)
      } else {
        this.options = []
      }
    },
    // 初始化选择街道
    initialdfun(w) {
      this.getcarboundinfofun(w)
      console.log(w)
    },
    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.getcarboundinfofun(this.area_id)
      // this.getnumbelistfun();
    },
    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.getcarboundinfofun(this.area_id)
    },
    editfun(ev) {
      // 打开编辑
      // 赋值
      var newdform = JSON.parse(JSON.stringify(ev))
      console.log(ev)
      // 修改属性为修改
      this.$set(this.dform, 'vehicle_no', newdform.info.vehicle_no)
      this.$set(this.dform, 'wasted_type', newdform.info.wasted_type.id * 1)
      this.$set(this.dform, 'area_id', newdform.info.area_id * 1)
      this.$set(this.dform, 'office_vehicle_id', newdform.base.id * 1)
      this.dform.vehicle_id = newdform.info.id * 1
      console.log(this.dform)

      this.tantype = 'edit'
      // 打开弹窗
      this.opentan()
    },
    lookfun(ld) {
      // 打开查看
      // console.log(ld);
      // 修改属性为修改
      this.tantype = 'look'
      // 打开弹窗
      this.opentan()
    },
    async delfun(id) {
      // 删除
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delvehiclefun(id)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 打开弹窗
    opentan() {
      this.dialogVisible = true
      this.getcarinfofun()
      this.getcarchooseinfofun()
      this.getcarstreetinfofun()
    },

    // 确定添加
    addgoto(type) {
      switch (type) {
        case 'add':
          this.$refs['dform'].validate(v => {
            if (v) {
              this.addnumbefun(this.dform)
            }
          })

          break
        case 'edit':
          this.$refs['dform'].validate(v => {
            if (v) {
              this.addnumbefun(this.dform)
            }
          })
          break
      }
    },
    // 取消关闭
    cancelfun() {
      this.handleClose()
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false
      this.dform = {}
      this.tantype = 'add'
    },
    // 请求区ლ(´ڡ`ლ)
    // 获取车辆信息n
    async getcarinfofun(key) {
      this.loading = true
      // const { data: data } = await GetCarInfo(this.pageDatas)
      const { data } = await GetautoList({ key: key, list_type: 'all' })
      this.options = data.data.list
      this.loading = false
    },
    // 获取车辆类型信息
    async getcarchooseinfofun() {
      const { data: data } = await GetCarChooseInfo(this.pageDatas)

      this.categories = data.data.categories
    },
    // 获取已绑定车辆类型信息
    async getcarboundinfofun(area_id) {
      const { data: data } = await GetCarBoundInfo({ area_id, page: this.pageData.page, size: this.pageData.size })
      this.getanpostfun(data, '获取')
      console.log(data)
      this.categories = data.data.pageData
      let arr = []
      data.data.data.map(item => {
        if (item.info.is_working === 1) {
          item.info.is_working_name = '停止工作'
        } else {
          item.info.is_working_name = '工作中'
        }
        arr.push(item)
      })
      //   this.tableData = data.data
      this.tableData = JSON.parse(JSON.stringify(arr))
      this.pageData.count = data.data.count
    },
    // 获取街道信息ID
    async getcarstreetinfofun() {
      const { data: data } = await GetCarStreetInfo(this.pageDatas)
      console.log(data, 'data')
      this.optionsone = data.data
    },
    async addnumbefun(v) {
      const { data: data } = await AddVehicle(v)
      this.area_id = this.dform.area_id
      this.getanpostfun(data, '添加', this.getcarboundinfofun(this.area_id))
    },
    // 删除已有车辆绑定
    async delvehiclefun(vehicle_id) {
      const { data: data } = await DelVehicle({ vehicle_id })
      this.getanpostfun(data, '删除', this.getcarboundinfofun(this.area_id))
    },
    // 请求区结束Ψ(￣∀￣)Ψ
    // 公共方法区└(^o^)┘└(^o^)┘
    // 请求后的判断
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        // 成功之后的文字展示
        this.$message.success(`${text}成功`)
        this.handleClose()
        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>
<style scoped></style>
